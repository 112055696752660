import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Book, BookCategory, BookChapters, BookPage, BookRequestResponse, BookSchema, UserBookRequestResponse } from '../models/book.model';
import { UserLocationState } from '../store/user-location.state';
import { DefaultProjectState } from '../store/default-project.state';
import { ReaderFontFamily, ReaderFontSize, ReaderLetterSpacing, ReaderLineSpacing } from '../pages/book-reader/components/book-reader.model';
import { Language } from './language.provider';
import { LanguageState } from '../store/language.state';
import { HttpClientService } from '../services/http-client.service';
import { UrlBuilder } from '../utils/url-builder';
import { Translatable } from '../models/translation.model';
import { BookReaderSettingsState } from '../store/book-reader-settings.state';

enum ApiEndpoint {
  Books = '/books',
  Book = '/books/:uuid',
  BookPage = '/books/:uuid/:version/page/:page',
  BookFinished = '/books/:uuid/finished',
  BookLanguage = '/books/languages',
  BookChapters = '/books/:uuid/:version/chapters',
  CategoryBooks = '/categories/:categoryId/books',
  BookCategories = '/categories/root',
  BookCategory = '/categories/:id',
  FeaturedActive = '/featured/active',
  FeaturedActiveBooks = '/featured/:id/books',
}

interface BookPageParams {
  encryption: boolean;
  width: number;
  height: number;
  itemId: string;
  noStyles: boolean;
  fontSize?: string;
  lineHeight?: string;
  letterSpacing?: number;
  fontFamily?: string;
  [key: string]: string | number | boolean | undefined;
}

const DEFAULT_PAGE_SIZE = 10;

@Injectable({ providedIn: 'root' })
export class BooksProvider {
  constructor(
    private http: HttpClient,
    private userLocationState: UserLocationState,
    private defaultProjectState: DefaultProjectState,
    private bookReaderSettingsState: BookReaderSettingsState,
    private languageState: LanguageState,
    private httpClientService: HttpClientService,
  ) {}

  searchBooks(params: { projectId: number; query: string; country: string }): Observable<Array<Book>> {
    const url = new UrlBuilder(ApiEndpoint.Books)
      .setQueryParams({
        project_id: params.projectId,
        limit: 20,
        book_schema: 'simple',
        search: params.query,
        country: params.country,
      })
      .getUrl();

    return this.http.get<BookRequestResponse>(environment.apiBaseUrl + url).pipe(map(res => res.books));
  }

  likeBook(uuid: string, profileId: string, like: boolean): Observable<void> {
    return this.http.patch<void>(`${environment.usersBaseUrl}/books/${uuid}?profileId=${profileId}`, {
      like,
    });
  }

  markBookAsFinished(
    uuid: string,
    profileId: string,
  ): Observable<{ firstTimeFinished: boolean; earnedPoints?: number; totalEarnedPoints?: number; totalFinishedBooks?: number }> {
    const url = new UrlBuilder(ApiEndpoint.BookFinished).setUrlParams({ uuid }).setQueryParams({ profileId }).getUrl();

    return this.http.post<{ firstTimeFinished: boolean }>(environment.usersBaseUrl + url, {});
  }

  getReadingHistory(profileId: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<Array<Book>> {
    return this.getReadingHistoryPaginated(profileId, offset, limit).pipe(map(res => res.books));
  }

  getReadingHistoryPaginated(profileId: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<BookRequestResponse> {
    const url = new UrlBuilder('/books')
      .setQueryParams({
        status: 'reading',
        profileId: profileId,
        offset: offset,
        limit: limit,
      })
      .getUrl();

    // We need to pass the totalCount of the first request otherwise we'll get the total count of the bookResponse.books which make not sense
    return this.http
      .get<UserBookRequestResponse>(environment.usersBaseUrl + url)
      .pipe(
        switchMap(bookResponse => this.getBooks(bookResponse.books, true).pipe(map(books => ({ ...books, totalCount: bookResponse.totalCount })))),
      );
  }

  getFavoritesBooks(profileId: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<Array<Book>> {
    return this.getFavoritesBooksPaginated(profileId, offset, limit).pipe(map(res => res.books));
  }

  getFavoritesBooksPaginated(profileId: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<BookRequestResponse> {
    const url = new UrlBuilder('/books')
      .setQueryParams({
        status: 'favorites',
        profileId: profileId,
        offset: offset,
        limit: limit,
      })
      .getUrl();

    // We need to pass the totalCount of the first request otherwise we'll get the total count of the bookResponse.books which make not sense
    return this.http
      .get<UserBookRequestResponse>(environment.usersBaseUrl + url)
      .pipe(switchMap(bookResponse => this.getBooks(bookResponse.books).pipe(map(books => ({ ...books, totalCount: bookResponse.totalCount })))));
  }

  getFinishedBooks(profileId: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<Array<Book>> {
    return this.getFinishedBooksPaginated(profileId, offset, limit).pipe(map(res => res.books));
  }

  getFinishedBooksPaginated(profileId: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<BookRequestResponse> {
    const url = new UrlBuilder('/books')
      .setQueryParams({
        status: 'finished',
        profileId: profileId,
        offset: offset,
        limit: limit,
      })
      .getUrl();

    // We need to pass the totalCount of the first request otherwise we'll get the total count of the bookResponse.books which make not sense
    return this.http
      .get<UserBookRequestResponse>(environment.usersBaseUrl + url)
      .pipe(switchMap(bookResponse => this.getBooks(bookResponse.books).pipe(map(books => ({ ...books, totalCount: bookResponse.totalCount })))));
  }

  getBooks(uuid: Array<string>, noFilters?: boolean): Observable<BookRequestResponse> {
    if (!uuid.length) {
      return of({
        books: [],
        count: 0,
        limit: 0,
        offset: 0,
        totalCount: 0,
      });
    }
    const url = new UrlBuilder(ApiEndpoint.Books)
      .setQueryParams({
        uuid: uuid,
        project_id: this.defaultProjectState.defaultProject$.value.id,
        country: this.userLocationState.userLocation$.value.countryCode,
        schema: BookSchema.Simple,
        language: noFilters ? undefined : this.languageState.language$.value.selected,
      })
      .getUrl();
    return this.http.get<BookRequestResponse>(environment.apiBaseUrl + url);
  }

  getFeaturedBooks(filterId?: number, languageId?: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<Array<Book>> {
    return this.getFeaturedBooksPaginated(filterId, languageId, offset, limit).pipe(map(res => res.books));
  }

  getFeaturedActive(): Observable<{ id: number; translatableTitle: Translatable }> {
    const url = new UrlBuilder(ApiEndpoint.FeaturedActive)
      .setQueryParams({
        project_id: this.defaultProjectState.defaultProject$.value.id,
        country: this.userLocationState.userLocation$.value.countryCode,
      })
      .getUrl();

    return this.http.get<{ id: number; translatableTitle: Translatable }>(environment.booksBaseUrl + url);
  }

  getFeaturedActiveBooks(id: number, filterId?: number, languageId?: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<Array<Book>> {
    return this.getFeaturedActiveBooksPaginated(id, filterId, languageId, offset, limit).pipe(map(res => res.books));
  }

  getFeaturedActiveBooksPaginated(
    id: number,
    filterId?: number,
    languageId?: string,
    offset = 0,
    limit = DEFAULT_PAGE_SIZE,
  ): Observable<BookRequestResponse> {
    const url = new UrlBuilder(ApiEndpoint.FeaturedActiveBooks)
      .setUrlParams({ id })
      .setQueryParams({
        project_id: this.defaultProjectState.defaultProject$.value.id,
        country: this.userLocationState.userLocation$.value.countryCode,
        grade_id: filterId,
        language: languageId,
        offset: offset,
        limit: limit,
      })
      .getUrl();

    return this.http.get<BookRequestResponse>(environment.booksBaseUrl + url);
  }

  getFeaturedBooksPaginated(filterId?: number, languageId?: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<BookRequestResponse> {
    const url = new UrlBuilder(ApiEndpoint.Books)
      .setQueryParams({
        project_id: this.defaultProjectState.defaultProject$.value.id,
        country: this.userLocationState.userLocation$.value.countryCode,
        grade_id: filterId,
        language: languageId,
        offset: offset,
        limit: limit,
        book_schema: 'minimal',
      })
      .getUrl();

    return this.http.get<BookRequestResponse>(environment.booksBaseUrl + url);
  }

  getPopularBooks(filterId?: number, languageId?: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<Array<Book>> {
    return this.getPopularBooksPaginated(filterId, languageId, offset, limit).pipe(map(res => res.books));
  }

  getPopularBooksPaginated(filterId?: number, languageId?: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<BookRequestResponse> {
    const url = new UrlBuilder(ApiEndpoint.Books)
      .setQueryParams({
        project_id: this.defaultProjectState.defaultProject$.value.id,
        country: this.userLocationState.userLocation$.value.countryCode,
        sort: 'timesOpened:desc',
        offset: offset,
        limit: limit,
        grade_id: filterId,
        language: languageId,
        book_schema: 'minimal',
      })
      .getUrl();

    return this.http.get<BookRequestResponse>(environment.booksBaseUrl + url);
  }

  getNewBooks(filterId?: number, languageId?: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<Array<Book>> {
    return this.getNewBooksPaginated(filterId, languageId, offset, limit).pipe(map(res => res.books));
  }

  getNewBooksPaginated(filterId?: number, languageId?: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<BookRequestResponse> {
    const countryCode = this.userLocationState.userLocation$.value.countryCode;
    const url = new UrlBuilder(ApiEndpoint.Books)
      .setQueryParams({
        project_id: this.defaultProjectState.defaultProject$.value.id,
        country: countryCode,
        sort: 'createdAt:desc',
        offset: offset,
        limit: limit,
        grade_id: filterId,
        language: languageId,
        book_schema: 'minimal',
      })
      .getUrl();

    return this.http.get<BookRequestResponse>(environment.booksBaseUrl + url);
  }

  getBookCategories(): Observable<BookCategory[]> {
    const url = new UrlBuilder(ApiEndpoint.BookCategories)
      .setQueryParams({
        project_id: this.defaultProjectState.defaultProject$.value.id,
        country: this.userLocationState.userLocation$.value.countryCode,
        limit: 10,
      })
      .getUrl();

    return this.http.get<BookCategory[]>(environment.booksBaseUrl + url);
  }

  getBookCategory(id: number): Observable<BookCategory> {
    const url = new UrlBuilder(ApiEndpoint.BookCategory)
      .setUrlParams({ id })
      .setQueryParams({
        project_id: this.defaultProjectState.defaultProject$.value.id,
        country: this.userLocationState.userLocation$.value.countryCode,
      })
      .getUrl();

    return this.http.get<BookCategory>(environment.booksBaseUrl + url);
  }

  getBooksByCategory(categoryId: number, filterId?: number, languageId?: string, offset = 0, limit = DEFAULT_PAGE_SIZE): Observable<Array<Book>> {
    return this.getBooksByCategoryPaginated(categoryId, filterId, languageId, offset, limit).pipe(map(res => res.books));
  }

  getBooksByCategoryPaginated(
    categoryId: number,
    filterId?: number,
    languageId?: string,
    offset = 0,
    limit = DEFAULT_PAGE_SIZE,
  ): Observable<BookRequestResponse> {
    const url = new UrlBuilder(ApiEndpoint.CategoryBooks)
      .setUrlParams({ categoryId: categoryId })
      .setQueryParams({
        project_id: this.defaultProjectState.defaultProject$.value.id,
        country: this.userLocationState.userLocation$.value.countryCode,
        limit: limit,
        offset: offset,
        grade_id: filterId,
        language: languageId,
        book_schema: 'minimal',
      })
      .getUrl();

    return this.http.get<BookRequestResponse>(environment.booksBaseUrl + url);
  }

  getBookLanguages(): Observable<Language[]> {
    const url = new UrlBuilder(ApiEndpoint.BookLanguage)
      .setQueryParams({
        project_id: this.defaultProjectState.defaultProject$.value.id,
        country: this.userLocationState.userLocation$.value.countryCode,
      })
      .getUrl();

    return this.http.get<Language[]>(environment.booksBaseUrl + url);
  }

  getBookChapters(params: { uuid: string; version: number }): Observable<BookChapters> {
    const url = new UrlBuilder(ApiEndpoint.BookChapters)
      .setUrlParams({
        uuid: params.uuid,
        version: params.version,
      })
      .getUrl();

    return this.http.get<BookChapters>(environment.contentBaseUrl + url);
  }

  getBook(uuid: string): Observable<Book> {
    const url = new UrlBuilder(ApiEndpoint.Book)
      .setUrlParams({ uuid })
      .setQueryParams({
        project_id: this.defaultProjectState.defaultProject$.value.id,
        country: this.userLocationState.userLocation$.value.countryCode,
        schema: BookSchema.Simple,
        language: this.languageState.language$.value.selected,
      })
      .getUrl();

    return this.http.get<Book>(environment.apiBaseUrl + url);
  }

  getBookPage(params: {
    uuid: string;
    bookVersion: number;
    page: number;
    width: number;
    height: number;
    itemId: string;
    fontSize: ReaderFontSize;
    lineSpacing: ReaderLineSpacing;
    letterSpacing: ReaderLetterSpacing;
    fontFamily: ReaderFontFamily;
  }): Observable<BookPage> {
    let lineSpacing;

    switch (params.lineSpacing) {
      case ReaderLineSpacing.DEFAULT:
        lineSpacing = params.fontSize;
        break;
      case ReaderLineSpacing.LARGE:
        lineSpacing = params.fontSize * 1.5;
        break;
      case ReaderLineSpacing.XLARGE:
        lineSpacing = params.fontSize * 2;
    }
    const customSettings = this.bookReaderSettingsState.bookReaderOptions$.value.customSettings;

    const queryParams: BookPageParams = {
      encryption: true,
      width: params.width,
      height: params.height,
      itemId: params.itemId,
      noStyles: !customSettings,
    };

    if (customSettings) {
      queryParams.fontSize = params.fontSize + 'rem';
      queryParams.lineHeight = lineSpacing + 'rem';
      queryParams.letterSpacing = params.letterSpacing;
      queryParams.fontFamily = params.fontFamily;
    }

    const url = new UrlBuilder(ApiEndpoint.BookPage)
      .setUrlParams({ uuid: params.uuid, version: params.bookVersion, page: params.page })
      .setQueryParams(queryParams)
      .getUrl();

    // TODO this is a temp fix. In some cases, keys are missing : https://worldreader.atlassian.net/browse/B20CP-501
    // Decrypt encrypted book page
    return this.http.get<BookPage>(environment.contentBaseUrl + url).pipe(
      map(encryptedResponse => {
        if (encryptedResponse.pageHtml && encryptedResponse.aesKey) {
          return {
            ...encryptedResponse,
            pageHtml: this.httpClientService.decryptResponse(encryptedResponse.pageHtml, encryptedResponse.aesKey),
          };
        } else {
          return encryptedResponse;
        }
      }),
    );
  }
}
